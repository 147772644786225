export const EVENT_EDIT = "eventEdited";
export const AUTHENTICATED = "signIn";
export const DEPOT_SET = "depotSet";
export const USER_SET = "userSet";
export const DEPOT_LIST_SET = "setDepotsList";
export const WORKSHOP_RELOAD = "reloadWorkshop";
export const BOOKINGS_COUNT_SET = "setBookingsCount";
export const EVENTS_ACTIVE_COUNT_SET = "setActiveEventsCount";
export const EVENTS_HISTORY_COUNT_SET = "setHistroyEventsCount";


